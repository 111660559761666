* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  font-family: "Poppins", sans-serif;
  color: #f9f9f9;
  background: linear-gradient(45deg, #1a4855, #885053) no-repeat;
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
}

a {
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}
