* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  color: #f9f9f9;
  background: linear-gradient(45deg, #1a4855, #885053) 0 0 / 400% 400% no-repeat;
  width: 100%;
  font-family: Poppins, sans-serif;
  animation: 20s infinite gradient;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: .8;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes gradient {
  0% {
    background-position: 0;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translate(var(--position));
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

.header {
  --position: 0, -20px;
  justify-content: space-between;
  align-items: center;
  max-width: 109.6rem;
  margin: 2.4rem auto 7.5rem;
  padding: 0 2.5rem;
  animation: 2s appear;
  display: flex;
}

.header__logo {
  width: 18rem;
}

.header__social a + a {
  margin-left: 3rem;
}

.header__social a img {
  width: 2.4rem;
}

.hero {
  grid-template-areas: "first second";
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  max-width: 109.6rem;
  margin: 0 auto 2rem;
  padding: 0 2.5rem;
  display: grid;
}

.hero__description {
  --position: -20px, 0;
  grid-area: first;
  max-width: 44.5rem;
  animation: 2s appear;
}

.hero__description h2 {
  font-size: 2rem;
  font-weight: 700;
}

.hero__description h1 {
  margin: 1.4rem 0 2.4rem;
  font-size: 6.8rem;
  font-weight: 900;
  line-height: 7.4rem;
}

.hero__description p {
  letter-spacing: 1.2px;
  font-size: 1.6rem;
  line-height: 2.88rem;
}

.hero__footer {
  justify-content: center;
  align-items: center;
  margin-top: 4.6rem;
  display: flex;
}

.hero__footer a {
  text-transform: uppercase;
  color: #f9f9f9;
  border: 2px solid #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 4.5rem;
  margin: 0 1.6rem 0 0;
  font-size: 1.6rem;
  text-decoration: none;
  display: flex;
  transform: skew(-20deg);
}

.hero__footer a:first-of-type {
  background: #497888;
}

.hero__footer a img {
  margin-right: 1rem;
}

.hero__illustration {
  grid-area: second;
  width: 100%;
  max-width: 500px;
  animation: 4s infinite move;
}

.modal-overlay {
  opacity: 0;
  visibility: hidden;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.modal {
  width: 90%;
  height: 90%;
  margin: 5vh auto;
  position: relative;
}

.modal__close {
  color: #f55;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 0;
}

iframe {
  border-radius: 8px;
  width: 100%;
  height: 95%;
  position: absolute;
  top: 5%;
}

@media only screen and (width <= 768px) {
  html {
    font-size: 60%;
  }

  .header__logo {
    width: 10rem;
  }

  .header__social a + a {
    margin-left: 2rem;
  }

  .header__social a img {
    width: 2rem;
  }

  .hero {
    grid-template-areas: "first"
                         "second";
  }

  .hero__description {
    grid-area: second;
  }

  .hero__description h1 {
    font-size: 4.8rem;
  }

  .hero__footer {
    flex-direction: column;
  }

  .hero__footer a {
    width: 100%;
    margin: 0 0 1.6rem;
  }

  .hero__illustration {
    grid-area: first;
  }
}

/*# sourceMappingURL=index.f053c5ab.css.map */
