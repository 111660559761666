.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.modal {
  position: relative;
  margin: 5vh auto;
  width: 90%;
  height: 90%;

  &__close {
    position: absolute;
    top: 4px;
    right: 0;
    color: #ff5555;
    cursor: pointer;
  }
}

iframe {
  top: 5%;
  position: absolute;
  width: 100%;
  height: 95%;
  border-radius: 8px;
}
