@keyframes move {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translate(var(--position));
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}